/*
 * @Author: your name
 * @Date: 2021-11-15 14:35:51
 * @LastEditTime: 2022-05-19 10:23:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\marketingActivitiesNew\api.js
 */
import http from "@/utils/request";

// post
export function getProductInfo(data) {
  return http({
    method: "post",
    url: "/temreport/productList.nd",
    data
  });
}

export function getTopInfo(data) {
  return http({
    method: "post",
    url: "/temreport/zonghe.nd",
    data
  });
}

//活动大厅查询内容-60名商家
export function getCustBuyMsg(data) {
  return http({
    method: "get",
    url: "/temreport/custBuyMsg.nd",
    params:data
  });
}

//活动大厅查询内容-分公司top10
export function getTop10(data) {
  return http({
    method: "get",
    url: "/temreport/top10.nd",
    params:data
  });
}

//活动大厅查询内容-达成目标
export function getTarget(data) {
  return http({
    method: "get",
    url: "/temreport/target.nd",
    params:data
  });
}


//revelry-------------------------
//营销中心
export function marketCenter(data) {
  return http({
    method: "get",
    url: "/fast/report/marketCenter.nd",
    params:data
  });
}

//产品线
export function spart(data) {
  return http({
    method: "get",
    url: "/fast/report/spart.nd",
    params:data
  });
}

//热销产品（明星产品）
export function hotproduct(data) {
  return http({
    method: "get",
    url: "/temreport/hotproduct.nd",
    params:data
  });
}

//商家排名
export function hotcust(data) {
  return http({
    method: "get",
    url: "/temreport/hotcust.nd",
    params:data
  });
}

//营销中心排名
export function hotcenter(data) {
  return http({
    method: "get",
    url: "/temreport/hotcenter.nd",
    params:data
  });
}

//任务完成进度
export function finishtask(data) {
  return http({
    method: "get",
    url: "/temreport/finishtask.nd",
    params:data
  });
}
// method=live&platform=XCX&loginType=CS
// clientPlatform:'dp'
//请求前调用
export function findByPlatform(data) {
  return http({
    method: "get",
    url: "/footerNavigationConfig/findByPlatform.nd",
    params:data
  });
}
//revelry-------------------------end
