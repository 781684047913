
<template>
  <div class="numberScroll">
    <div class="screenMini" v-for="(item, index) in numberToArray" :key="index" :class="{douhao:item == ','}">
    <div  v-if="item == ','">
        ,
      </div>
      <div :style="reStyle(item)" v-if="item != ','">
        <div v-for="(v, i) in numberBox" :key="i">
            <font>{{ v }}</font>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * 传入值String, Number
     */
    number: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      numberBox: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      numberToArray: [],
    };
  },
  methods: {
    reStyle(number) {
      return `transform:translate(0,-${+number * 0.8}em)`;
    },
    //千分位 金额转换
    numberChange(number, decimals, decPoint, thousandsSep, roundtag) {
      /*
       * 参数说明：
       * number：要格式化的数字
       * decimals：保留几位小数
       * dec_point：小数点符号
       * thousands_sep：千分位符号
       * roundtag:舍入参数，默认 'ceil' 向上取,'floor'向下取,'round' 四舍五入
       * */
      number = (number + "").replace(/[^0-9+-Ee.]/g, "");
      roundtag = roundtag || "ceil"; // 'ceil','floor','round'
      var n = !isFinite(+number) ? 0 : +number;
      var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
      var sep = typeof thousandsSep === "undefined" ? "," : thousandsSep;
      var dec = typeof decPoint === "undefined" ? "." : decPoint;
      var s = "";
      var toFixedFix = function(n, prec) {
        var k = Math.pow(10, prec);

        return (
          "" +
          parseFloat(
            Math[roundtag](parseFloat((n * k).toFixed(prec * 2))).toFixed(
              prec * 2
            )
          ) /
            k
        );
      };
      s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
      var re = /(-?\d+)(\d{3})/;
      while (re.test(s[0])) {
        s[0] = s[0].replace(re, "$1" + sep + "$2");
      }

      if ((s[1] || "").length < prec) {
        s[1] = s[1] || "";
        s[1] += new Array(prec - s[1].length + 1).join("0");
      }
      return s.join(dec);
    },
  },
  watch: {
    number: {
      immediate: true,
      deep: true,
      handler(num) {
        const resNum = this.numberChange(num,0);
        const arr = resNum.toString().split("");
        this.numberToArray = new Array(arr.length).fill(0);
        // setTimeout(() => {
        this.numberToArray = arr;
        // }, 10);
      },
    },
  },
};
</script>
<style lang="less">
.numberScroll {
  display: flex;
  align-items: flex-end;
  margin-right: .14em;
  .screenMini {
    height: 0.8em;
    width: 0.66em;
    overflow: hidden;
    background: url("~@/assets/saleActivity/n-btm-1-num-bg.png") no-repeat;
    background-size: 100% 100%;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    + .screenMini{
        margin-left: .08em;
    }
    &.douhao{
        width: 0.14em;
        height: 0.22em;
        background: url("~@/assets/saleActivity/btm-1-douhao.png") no-repeat;
        background-size: 100% 100%;
    }
    & > div {
      display: flex;
      flex-direction: column;
      transform: translate(0, 0);
      transition: all 1.6s;
      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        overflow: hidden;
        height: 0.8em;
        font{
            font-size: 0.54em;
            font-family: "DIGIB";
            color: #fad467;
        }
      }
    }
  }
}
</style>
