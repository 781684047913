<!--
 * @Author: sqk
 * @Date: 2020-08-12 10:40:56
 * @LastEditTime: 2022-02-08 14:27:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\Carousel\Carousel.vue
-->

<template>
  <div class="swiper-container activity-swiper" ref="actSwiper">
    <div class="swiper-wrapper">
       <div
        class="swiper-slide"
        :data-idx="index"
        v-for="(item,index) in dataArr"
        :key="index"
      >
      <ul :class="{ small: item.length < 10 }">
        <li 
          v-for="(e,idx) in item"
          :key="idx"
          :class="{isSmall: e.isSmall}"
          >
          <!-- marketCenter -->
          <em v-if="e.orgName" :style="{ width: e.maxLen * .22 + .2 + 'em'}"><font>{{e.orgName}}</font></em>
          <span>{{e.custName}}</span>
          <font>{{e.buyNum}}台</font>
        </li>
      </ul>
        <!-- <div
          v-for="(e,idx) in item"
          :key="idx"
        >
           
        </div> -->
     
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cust-buy-swiper",
  data() {
    return {
      actSwiper:null,
      // loop:false
    };
  },
  props: {
    dataArr: {
      type: Array,
      default: () => []
    },
    baseUrl: {
      type: String,
      default: ""
    }
  },
  watch: {
    // dataArr: function() {
    //   alert(12121)
    //   // this.initEcharts();
    //   this.swiperInit();
    // }
    dataArr: {
      handler(newData, oldData) {
        if((newData.length > 1 && !this.actSwiper) || (newData.length > 1 && oldData.length == 1)){
          this.swiperInit();
        }
        if(newData.length > 1 && JSON.stringify(newData) != JSON.stringify(oldData)){
          // this.loop = false;
          this.$nextTick(() => {
            setTimeout(()=>{
              $(".swiper-wrapper .swiper-slide[data-idx=0]").eq(1).html($(".swiper-wrapper .swiper-slide[data-idx=0]").eq(0).html()) 
              this.actSwiper.init();
              this.actSwiper.update();
              
            },50)
          })
          
          // this.actSwiper.updateSlides()
        }else if((this.dataArr.length == 0 && this.actSwiper) || (newData.length < 2 && this.actSwiper)){
          this.actSwiper.removeAllSlides(); //移除全部
          this.actSwiper.destroy(false);
          this.actSwiper = null;
        }else if(newData.length > 0 && JSON.stringify(newData) == JSON.stringify(oldData)){
          // setTimeout(()=>{
          // this.actSwiper.autoplay.start()
          // this.swiperInit();
          // this.actSwiper.init();
          // this.actSwiper.update();
          // },0)
        }
      },
      immediate: true,
      deep: true
    }
  },
  //定义swiper对象
  computed: {},
  mounted() {
    // this.$nextTick(() => {
    //   this.swiperInit();
    // })
  },
  methods: {
    borderColorFun(index) {
      let idx = index % 4;
      let colorList = ["#FF8F3C", "#00B7B3", "#4285FF", "#D9161C"];
      return colorList[idx];
    },
    swiperInit() {
      let this_ = this;
      this.$nextTick(() => {
        this.actSwiper = new this.$Swiper(this.$refs.actSwiper, {
          autoplay: {
            //自动轮播
            delay: 2000
            // disableOnInteraction: false
          },
          // effect: "fade", //渐变形式
          // fadeEffect:{crossFade:true},//防止loop后重叠
          loop: this_.dataArr && this_.dataArr.length > 1 ? true : false, // 循环模式选项
          // initialSlide :0,
          // loop:true,
          // loop:this_.loop,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper

          simulateTouch: false,//鼠标模拟手机触摸。默认为true，Swiper接受鼠标点击、拖动。


          // notNextTick: true,
          direction: "vertical",
          speed:500,
          //  on: {
          //     slideChangeTransitionEnd: function(){
          //       if(this.activeIndex == 2){
          //         // if(!this_.loop){
          //         //   this_.actSwiper.autoplay.stop();
          //         // }
          //       }
                
          //     },
          //   },
        });
        // actSwiper.el.onmouseover = function() {
        //   //鼠标放上暂停轮播
        //   actSwiper.autoplay.stop();
        // };
        // actSwiper.el.onmouseleave = function() {
        //   actSwiper.autoplay.start();
        // };
      });
      this.$nextTick(() => {
        this.actSwiper.init() // 再初始化swiper
      })
    }
  }
};
</script>
<style lang="less">
.activity-swiper {
  width: 100%;
  height: 100%;
  z-index: 0;
  transform:translate3d(0,0,0);
  overflow:hidden;
  .swiper-slide{
    width: 100%;
    height: 100%;
    transform:translate3d(0,0,0);
    ul{
      width: 100%;
      height: 100%;
      padding: 0 .3em;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      // align-items: center;
      align-content: space-between;
      &.small{
        align-content: flex-start;
        li{
          margin-bottom: .15em;
        }
      }
      li{
        // width: calc(50% - .1em);
        width: 100%;
        height: .3em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:nth-child(odd){
        }
        &:nth-child(even){
        }
        // &.isSmall{
        //   em{
        //     width: 1.06em;
        //   }
        // }
        em{
          // margin-right: .2em;
          display: flex;
          justify-content: start;
          align-items: center;
          width: 1.6em;
          font{
            font-size: .22em;
            // color: #00D0A1;
            color: #e8e8e8;
            font-weight: 300;
            text-align: left;
          }
        }
        span{
          font-size: .22em;
          // color: #00D0A1;
          color: #e8e8e8;
          flex:1;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          font-weight: 300;
          text-align: left;
        }
        font{
          font-size: .22em;
          // color: #00D0A1;
          color: #e8e8e8;
          font-weight: 300;
        }
      }
    }
  }
}
</style>

