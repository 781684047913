/*
 * @Author: your name
 * @Date: 2021-11-16 14:27:26
 * @LastEditTime: 2021-11-28 15:27:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\marketingActivitiesNew\getData.js
 */
export default {
  productList: {
    msg: "success",
    code: 0,
    time: 1637045586517,
    list: [
      {
        code: 14322186434,
        stepPrice: "3600-3500-3400",
        standardPrice: 8899.0,
        onSaleDate: "2016-06-03",
        imgUrl: require("@/assets/saleActivity/mid-1-productInfo.png"),
        importInfo: "32吋液晶电视",
        billPrice: 7899.0,
        targetNum: 3600,
        stepNum: "30-70-100",
        name: "LED32K30JD",
        matklName: "电视",
        deposit: 0.0,
        brand: "海信",
        productInfoId: 3448836,
      },
      {
        code: 14322186435,
        stepPrice: "3600-3500-3400",
        standardPrice: 998.0,
        onSaleDate: "2016-05-03",
        imgUrl: require("@/assets/saleActivity/mid-1-productInfo.png"),
        importInfo: null,
        billPrice: 599.0,
        targetNum: 3600,
        stepNum: "30-70-100",
        name: "LED26K200J",
        matklName: "电视",
        deposit: 0.0,
        brand: "海信",
        productInfoId: 3448667,
      },
      {
        code: 14322186436,
        stepPrice: "3600-3500-3400",
        standardPrice: 5998.0,
        onSaleDate: "2016-05-03",
        imgUrl: require("@/assets/saleActivity/mid-1-productInfo.png"),
        importInfo: null,
        billPrice: 5488.0,
        targetNum: 3600,
        stepNum: "30-70-100",
        name: "LED32K190",
        matklName: "电视",
        deposit: 0.0,
        brand: "海信",
        productInfoId: 3448828,
      },
      {
        code: 14322186437,
        stepPrice: "3600-3500-3400",
        standardPrice: 4998.0,
        onSaleDate: "2016-05-27",
        imgUrl: require("@/assets/saleActivity/mid-1-productInfo.png"),
        importInfo: "4K、ULED、超薄、玫瑰金色金属边框、PCM背板",
        billPrice: 4588.0,
        targetNum: 3600,
        stepNum: "30-70-100",
        name: "LED50MU7000U",
        matklName: "电视",
        deposit: 0.0,
        brand: "海信",
        productInfoId: 3459270,
      },
      {
        code: 14322186438,
        stepPrice: "3600-3500-3400",
        standardPrice: 3998.0,
        onSaleDate: "2021-09-16",
        imgUrl: require("@/assets/saleActivity/mid-1-productInfo.png"),
        importInfo: "4K、智能、VIDAA3、十四核处理器",
        billPrice: 3666.0,
        targetNum: 3600,
        stepNum: "30-70-100",
        name: "LED50K300UD",
        matklName: "电视",
        deposit: 0.0,
        brand: "海信",
        productInfoId: 31211414,
      },
      {
        code: 14322186439,
        stepPrice: "3600-3500-3400",
        standardPrice: 2998.0,
        onSaleDate: "2021-09-17",
        imgUrl: require("@/assets/saleActivity/mid-1-productInfo.png"),
        importInfo: "4K、智能、VIDAA3、十四核处理器",
        billPrice: 2599.0,
        targetNum: 3600,
        stepNum: "30-70-100",
        name: "LED50K300U",
        matklName: "电视",
        deposit: 0.0,
        brand: "海信",
        productInfoId: 3451028,
      },
    ],
  },
  mbdata:{
    targetNum:5000,
    finishNUm:3000,
    unFinishNum:2000,
    stepNum:'30-70-100',
    stepPrice:"3000-5000-10000"
  },
  top10: {
    code: 0, //成功
    list: [
      {
        buyNum: 389,
        orgName: "冰箱广州",
        rankFlag: 1, //0未变化，1上升，-1下降
      },
      {
        buyNum: 348,
        orgName: "冰箱青岛",
        rankFlag: -1, //0未变化，1上升，-1下降
      },
      {
        buyNum: 320,
        orgName: "青岛冰箱",
        rankFlag: 0, //0未变化，1上升，-1下降
      },
      {
        buyNum: 286,
        orgName: "冰箱广州",
        rankFlag: 1, //0未变化，1上升，-1下降
      },
      {
        buyNum: 270,
        orgName: "股份青岛",
        rankFlag: -1, //0未变化，1上升，-1下降
      },
      {
        buyNum: 266,
        orgName: "冰箱杭州",
        rankFlag: 0, //0未变化，1上升，-1下降
      },
      {
        buyNum: 240,
        orgName: "股份济南",
        rankFlag: -1, //0未变化，1上升，-1下降
      },
      {
        buyNum: 236,
        orgName: "股份烟台",
        rankFlag: 1, //0未变化，1上升，-1下降
      },
      {
        buyNum: 220,
        orgName: "空调菏泽",
        rankFlag: 0, //0未变化，1上升，-1下降
      },
      {
        buyNum: 216,
        orgName: "空调曹县",
        rankFlag: -1, //0未变化，1上升，-1下降
      },
    ],
  },
  //60
  custList: [
    { orgName:'冰箱乌鲁木齐', buyNum: 1123, type: "认购", custName: "青岛4宇恒电器有限公司" },
    { orgName:'冰箱青岛', buyNum: 3221, type: "认购", custName: "哈尔滨兴隆电器有限公司" },
    { orgName:'冰箱青岛', buyNum: 45, type: "认购", custName: "烟台兴隆电器冰箱有限公司" },
    { orgName:'冰箱青岛', buyNum: 7786, type: "认购", custName: "杭州股份电器有限公司" },
    { orgName:'冰箱青岛', buyNum: 5842, type: "认购", custName: "新世界1电器股份有限公司" },
    { orgName:'冰箱青岛', buyNum: 123, type: "认购", custName: "青岛宇恒电器有限公司" },
    { orgName:'冰箱青岛', buyNum: 321, type: "认购", custName: "哈尔滨兴隆电器有限公司" },
    { orgName:'冰箱青岛', buyNum: 45, type: "认购", custName: "烟台兴隆电器冰箱有限公司" },
    { orgName:'冰箱青岛', buyNum: 7786, type: "认购", custName: "杭州股份电器有限公司" },
    { orgName:'冰箱青岛', buyNum: 582, type: "认购", custName: "新世界电器股份有限公司" },
    { orgName:'冰箱青岛', buyNum: 123, type: "认购", custName: "青岛宇恒电器有限公司" },
    { orgName:'冰箱青岛', buyNum: 321, type: "认购", custName: "哈尔滨兴隆电器有限公司" },
    { orgName:'冰箱青岛', buyNum: 45, type: "认购", custName: "烟台兴隆电器冰箱有限公司" },
    { orgName:'冰箱青岛', buyNum: 7786, type: "认购", custName: "杭州股份电器有限公司" },
    { orgName:'冰箱青岛', buyNum: 582, type: "认购", custName: "新世界电器股份有限公司" },
    { orgName:'冰箱青岛', buyNum: 123, type: "认购", custName: "青岛宇恒电器有限公司" },
    { orgName:'冰箱青岛', buyNum: 321, type: "认购", custName: "哈尔滨兴隆电器有限公司" },
    { orgName:'冰箱乌鲁', buyNum: 45, type: "认购", custName: "烟台兴隆电器冰箱有限公司" },
    { orgName:'冰箱青岛', buyNum: 7786, type: "认购", custName: "杭州股份电器有限公司" },
    { orgName:'冰箱青岛', buyNum: 582, type: "认购", custName: "新世界电器股份有限公司" },
    // { orgName:'冰箱青岛', buyNum: 123, type: "认购", custName: "青岛宇恒电器有限公司" },
    // { orgName:'冰箱青岛', buyNum: 321, type: "认购", custName: "哈尔滨兴隆电器有限公司" },
    // { orgName:'冰箱青岛', buyNum: 45, type: "认购", custName: "烟台兴隆电器冰箱有限公司" },
    // { orgName:'冰箱青岛', buyNum: 7786, type: "认购", custName: "杭州股份电器有限公司" },
    // { orgName:'冰箱青岛', buyNum: 582, type: "认购", custName: "新世界电器股份有限公司" },
    // { orgName:'冰箱青岛', buyNum: 123, type: "认购", custName: "青岛宇恒电器有限公司" },
    // { orgName:'冰箱青岛', buyNum: 321, type: "认购", custName: "哈尔滨兴隆电器有限公司" },
    // { orgName:'冰箱青岛', buyNum: 45, type: "认购", custName: "烟台兴隆电器冰箱有限公司" },
    // { orgName:'冰箱青岛', buyNum: 7786, type: "认购", custName: "杭州股份电器有限公司" },
    // { orgName:'冰箱青岛', buyNum: 582, type: "认购", custName: "新世界电器股份有限公司" },
    // { orgName:'冰箱青岛', buyNum: 123, type: "认购", custName: "青岛宇恒电器有限公司" },
    // { orgName:'冰箱青岛', buyNum: 321, type: "认购", custName: "哈尔滨兴隆电器有限公司" },
    // { orgName:'冰箱青岛', buyNum: 45, type: "认购", custName: "烟台兴隆电器冰箱有限公司" },
    // { orgName:'冰箱青岛', buyNum: 7786, type: "认购", custName: "杭州股份电器有限公司" },
    // { orgName:'冰箱青岛', buyNum: 582, type: "认购", custName: "新世界电器股份有限公司" },
    // { orgName:'冰箱青岛', buyNum: 123, type: "认购", custName: "青岛宇恒电器有限公司" },
    // { orgName:'冰箱青岛', buyNum: 321, type: "认购", custName: "哈尔滨兴隆电器有限公司" },
    // { orgName:'冰箱青岛', buyNum: 45, type: "认购", custName: "烟台兴隆电器冰箱有限公司" },
    // { orgName:'冰箱青岛', buyNum: 7786, type: "认购", custName: "杭州股份电器有限公司" },
    // { orgName:'冰箱青岛', buyNum: 582, type: "认购", custName: "新世界电器股份有限公司" },
    // { orgName:'冰箱青岛', buyNum: 123, type: "认购", custName: "青岛宇恒电器有限公司" },
    // { orgName:'冰箱青岛', buyNum: 321, type: "认购", custName: "哈尔滨兴隆电器有限公司" },
    // { orgName:'冰箱青岛', buyNum: 45, type: "认购", custName: "烟台兴隆电器冰箱有限公司" },
    // { orgName:'冰箱青岛', buyNum: 7786, type: "认购", custName: "杭州股份电器有限公司" },
    // { orgName:'冰箱青岛', buyNum: 582, type: "认购", custName: "新世界电器股份有限公司" },
    // { orgName:'冰箱青岛', buyNum: 123, type: "认购", custName: "青岛宇恒电器有限公司" },
    // { orgName:'冰箱青岛', buyNum: 321, type: "认购", custName: "哈尔滨兴隆电器有限公司" },
    // { orgName:'冰箱青岛', buyNum: 45, type: "认购", custName: "烟台兴隆电器冰箱有限公司" },
    // { orgName:'冰箱青岛', buyNum: 7786, type: "认购", custName: "杭州股份电器有限公司" },
    // { orgName:'冰箱青岛', buyNum: 582, type: "认购", custName: "新世界电器股份有限公司" },
    // { orgName:'冰箱青岛', buyNum: 123, type: "认购", custName: "青岛宇恒电器有限公司" },
    // { orgName:'冰箱青岛', buyNum: 321, type: "认购", custName: "哈尔滨兴隆电器有限公司" },
    // { orgName:'冰箱青岛', buyNum: 45, type: "认购", custName: "烟台兴隆电器冰箱有限公司" },
    // { orgName:'冰箱青岛', buyNum: 7786, type: "认购", custName: "杭州股份电器有限公司" },
    // { orgName:'冰箱青岛', buyNum: 582, type: "认购", custName: "新世界电器股份有限公司" },
  ],
};
